<template>
  <div class="approval">
    <div class="approval-title">
      <h1>调整预算</h1>
    </div>
    <div class="approval-side" v-if="!loading">
      <div v-if="!is_audit" class="approval-screen">
       <!-- 单日 -->
        <table class="dispaly-table">
            <th class="first-title"></th>
            <th>单日</th>
            <th>单月</th>
            <th v-if="businessInfo.id == 958">季度</th>
            <th class="last-title">总</th>
            <tr class="moment-approval">
              <td class="first-content">当前</td>
              <td>{{ now_daily_val || '--' }}</td>
              <td>{{ now_monthl_val || '--' }}</td>
              <td v-if="businessInfo.id == 958">{{ now_quarter_val || '--' }}</td>
              <td class="last-content">{{ now_all_val || '--' }}</td>
            </tr>
          </table>
        <div class="approval-content">
          <div class="day-limited">
            <p class="limited-title">单日采购上限</p>
            <div class="limited-radio">
              <yd-radio-group v-model="daily_limit">
                <yd-radio val="1">不限制</yd-radio>
                <yd-radio val="0">限制</yd-radio>
              </yd-radio-group>
            </div>
            <div v-if="!daily_limit" class="limited-radio limited-mount">
              <input type="number" class="number" v-model="daily_val" onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'0')}else{this.value=this.value.replace(/\D/g,'')}" />
            </div>
          </div>
        </div>
        <!-- 单月 -->
        <div class="approval-content">
          <div class="day-limited">
            <p class="limited-title">单月采购上限</p>
            <div class="limited-radio">
              <yd-radio-group v-model="monthlLimit">
                <yd-radio val="1">不限制</yd-radio>
                <yd-radio val="0">限制</yd-radio>
              </yd-radio-group>
            </div>
            <div v-if="!monthlLimit" class="limited-radio limited-mount">
              <input type="number" class="number" v-model="monthl_val" onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'0')}else{this.value=this.value.replace(/\D/g,'')}" />
            </div>
          </div>
        </div>
        <!-- 季度 -->
        <div  v-if="businessInfo.id == 958" class="approval-content">
          <div class="day-limited">
            <p class="limited-title">季度采购上限</p>
            <div class="limited-radio">
              <yd-radio-group v-model="quarterLimit">
                <yd-radio val="1">不限制</yd-radio>
                <yd-radio val="0">限制</yd-radio>
              </yd-radio-group>
            </div>
            <div v-if="!quarterLimit" class="limited-radio limited-mount">
              <input type="number" class="number" v-model="quarter_val" onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'0')}else{this.value=this.value.replace(/\D/g,'')}" />
            </div>
          </div>
        </div>
        <!-- 累计采购 -->
        <div class="approval-content">
          <div class="day-limited">
            <p class="limited-title">累计采购上限</p>
            <div class="limited-radio">
              <yd-radio-group v-model="allLimit">
                <yd-radio val="1">不限制</yd-radio>
                <yd-radio val="0">限制</yd-radio>
              </yd-radio-group>
            </div>
            <div v-if="!allLimit" class="limited-radio limited-mount">
              <input type="number" class="number" v-model="all_val" onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'0')}else{this.value=this.value.replace(/\D/g,'')}" />
            </div>
          </div>
        </div>

        <div class="approval-btn" @click="submit">
          <Button :title="submit_btn"></Button>
        </div>
      </div>
      <div v-else class="approval-screen">
        <div class="approval-content">
          <table>
            <th class="first-title"></th>
            <th>单日</th>
            <th>单月</th>
            <th v-if="businessInfo.id == 958" class="last-title">季度</th>
            <th class="last-title">总</th>
            <tr class="moment-approval">
              <td class="first-content">当前</td>
              <td class="second-content">{{ now_daily_val || '--' }}</td>
              <td class="third-content">{{ now_monthl_val || '--' }}</td>
              <td v-if="businessInfo.id == 958" class="last-content">{{ now_quarter_val || '--' }}</td>
              <td class="last-content">{{ now_all_val || '--' }}</td>
            </tr>
            <tr class="audit-approval">
              <td class="first-content">审批中</td>
              <td class="second-content">{{ daily_val || '--' }}</td>
              <td class="third-content">{{ monthl_val || '--' }}</td>
              <td v-if="businessInfo.id == 958">{{ quarter_val || '--' }}</td>
              <td>{{ all_val || '--' }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div v-else>
    </div>
  </div>
</template>

<script>
import Button from '@/components/button/button.vue'
import { postBudgetApply, getBudgetApplyInfo } from '@/services/my'
import {mapState} from "vuex";
export default {
  name: "Approval",
  data () {
    return {
      daily_limit: Number,  //  单选框
      monthlLimit: Number,
      allLimit: Number,
      quarterLimit: Number,
      submit_btn: '提交',
      cancel_btn: '取消',
      sub_btn_color: 'submit',
      cancel_btn_color: 'cancel',
      daily_val: '',  //  输入框value
      monthl_val: '',
      all_val: '',
      quarter_val: '',
      daily_status: false,  //  审核中显示状态
      monthly_status: false,
      all_status: false,
      is_audit: Number,
      old_daily_val: '',
      old_monthl_val: '',
      old_all_val: '',
      now_daily_val: '',
      now_monthl_val: '',
      now_all_val: '',
      now_quarter_val: '',
      loading: false,
      virtual_daily_limit: Number,
      virtual_monthlLimit: Number,
      virtual_allLimit: Number,
      virtual_quarterLimit: Number
    }
  },
  watch: {
    daily_limit (val) {
      this.daily_limit = Number(val)
    },
    monthlLimit (val) {
      this.monthlLimit = Number(val)
    },
    allLimit (val) {
      this.allLimit = Number(val)
    },
    quarterLimit(val) {
      this.quarterLimit = Number(val)
    }
  },
  computed: {
    ...mapState({
      businessInfo: state => state.businessInfo,//1现金商城，2积分商品，3内采商城
    }),
  },
  created () {
    this.getBudgetApplyInfo()
  },
  methods: {
    submit () {
      if (isNaN(Number(this.daily_limit)) && isNaN(Number(this.monthlLimit)) && isNaN(Number(this.allLimit)) && isNaN(Number(this.quarterLimit))) {
        this.$dialog.toast({
          mes: "未进行任何修改"
        });
        return;
      }
      if (!this.daily_limit && !this.daily_val && this.now_daily_val) {
        this.$dialog.toast({
          mes: "请填写单日采购上限数量"
        });
        return;
      }
      if (!this.monthlLimit && !this.monthl_val && this.now_monthl_val) {
        this.$dialog.toast({
          mes: "请填写单月采购上限数量"
        });
        return;
      }
      if (!this.all_val && !this.allLimit && this.now_all_val) {
        this.$dialog.toast({
          mes: "请填写累计采购上限数量"
        });
        return;
      }
      if (!this.quarter_val && !this.quarterLimit && this.now_quarter_val && this.businessInfo.id == 958) {
        this.$dialog.toast({
          mes: "请填写季度采购上限数量"
        });
        return;
      }
      this.postBudgetApply()
    },
    cancel () {
      this.$router.go(-1)
    },
    async postBudgetApply () {
      if (!isNaN(Number(this.daily_limit))) {
        if (Number(this.now_daily_val) >= 0) {
          this.virtual_daily_limit = 0
        } else {
          this.virtual_daily_limit = 1
        }
      }
      if (!isNaN(Number(this.monthlLimit))) {
        if (Number(this.now_monthl_val) >= 0) {
          this.virtual_monthlLimit = 0
        } else {
          this.virtual_monthlLimit = 1
        }
      }
      if (!isNaN(Number(this.allLimit))) {
        if (Number(this.now_all_val) >= 0) {
          this.virtual_allLimit = 0
        } else {
          this.virtual_allLimit = 1
        }
      }
      if (!isNaN(Number(this.quarterLimit))) {
        if (Number(this.now_quarter_val) >= 0) {
          this.virtual_virtual_quarterLimit = 0
        } else {
          this.virtual_virtual_quarterLimit = 1
        }
      }
      if (this.now_daily_val === '无限制') {
        this.now_daily_val = ''
        this.virtual_daily_limit = 1
      }
      if (this.now_monthl_val === '无限制') {
        this.now_monthl_val = ''
        this.virtual_monthlLimit = 1
      }
      if (this.now_all_val === '无限制') {
        this.now_all_val = ''
        this.virtual_allLimit = 1
      }
      if (this.now_quarter_val === '无限制') {
        this.now_quarter_val = ''
        this.virtual_quarterLimit = 1
      }
      let params = {
        day_budget_status: !isNaN(Number(this.daily_limit)) ? this.daily_limit : this.virtual_daily_limit,
        month_budget_status: !isNaN(Number(this.monthlLimit)) ? this.daily_limit : this.virtual_monthlLimit,
        all_budget_status: !isNaN(Number(this.allLimit)) ? this.allLimit : this.virtual_allLimit,
        quarter_budget_status: !isNaN(Number(this.quarterLimit)) ? this.quarterLimit : this.virtual_quarterLimit,
        day_budget: this.daily_val || this.now_daily_val,
        month_budget: this.monthl_val || this.now_monthl_val,
        all_budget: this.all_val || this.now_all_val,
        quarter_budget: this.quarter_val || this.now_quarter_val
      }
      try {
        this.$dialog.loading.open("提交中，请稍等");
        const res = await postBudgetApply(params)
        if (!res.code) {
          this.$dialog.loading.close()
          this.getBudgetApplyInfo() 
          this.is_audit = 1
        } else {
          this.$dialog.loading.close()
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1000,
            icon: "error"
          });
        }
      } catch (e) {}
    },
    // 获取审核预算内容
    async getBudgetApplyInfo () {
      try {
        this.loading = true
        const res = await getBudgetApplyInfo()
        if (!res.code) {
          this.loading = false
          this.is_audit = res.data.status
          if (this.is_audit) { 
            this.daily_val = res.data.day_budget
            this.monthl_val = res.data.month_budget
            this.all_val = res.data.all_budget
            this.quarter_val = res.data.quarter_budget
            this.now_daily_val = res.data.now_day_budget
            this.now_monthl_val = res.data.now_month_budget
            this.now_all_val = res.data.now_all_budget
            this.now_quarter_val = res.data.now_quarter_budget
          } else {
            this.now_daily_val = res.data.now_day_budget
            this.now_monthl_val = res.data.now_month_budget
            this.now_all_val = res.data.now_all_budget
            this.now_quarter_val = res.data.now_quarter_budget
          }
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1000,
            icon: "error"
          });
        }
      } catch (e) {}
    }
  },
  components: {
    Button
  }
}
</script>

<style lang="less" scoped>
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";
.approval {
  height: 100vh;
  background: #fff;
  .approval-title {
    width: 100%;
    height: 0.96rem;
    background: var(--main-color);
    line-height: 0.96rem;
    padding: 0 0.24rem;
    font-size: 0.32rem;
    color: #fff;
  }
  .approval-side {
    padding: 0.2rem;
  }
  .approval-screen {
    background: #fff;
    padding-bottom: 0.8rem;
    .dispaly-table {
      margin: 0.5rem 0;
    }
    .approval-content {
      margin-top: 0.2rem;
      height: 1rem;
      .day-limited {
        overflow: hidden;
        padding-top: 0.2rem;
        .limited-title {
          padding-top: 0.05rem;
        }
        .limited-radio, .limited-title {
          float: left;
        }
        .limited-radio {
          margin-left: 0.2rem;
        }
        .limited-mount {
          border: 0.02rem solid #999;
          .number {
            width: 1.5rem;
            padding: 0.05rem 0.1rem;
          }
        }
        .approval {
          .wh(1.2rem, 0.5rem);
          line-height: 0.5rem;
          text-align: center;
          background: #e6a23c;
          font-size: 0.1rem;
          border-radius: 0.1rem;
        }
      }
    }
    .approval-btn {
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-around;
    }
  }
  table {
    font-size: 0.3rem;
    th {
      .wh(2.1rem, 0.6rem);
      text-align: right;
      line-height: 0.6rem;
    }
    .first-title {
      .wh(1.4rem, 0.5rem);
      width: calc(100vw - 6rem);
      padding-right: 0.2rem;
    }
    .last-title {
      .wh(1.8rem, 0.5rem);
      border: none;
    }
    tr {
      border:solid #eee;
      border-width:0.01rem 0rem 0rem 0;
      td {
        height: 0.6rem;
        text-align: right;
        line-height: 0.6rem;
      }
      .first-content {
        text-align: right;
        padding-right: 0.2rem;
        width:  2rem;
      }
    }
  }
}

</style>